import { tryit } from "radash";
import { i18nMapping } from "~/lib/utils";
import { injectPageType } from "~/types/injects";

type AlternateLinkGenerator = (options: { language: string }) => string;

export const generateAlternateLinksWithCanonical = (
  language: string,
  generator: AlternateLinkGenerator,
) => {
  const baseUrl = "https://www.voyagecopilot.com";

  const links = [
    {
      rel: "canonical",
      href: generator({ language }),
    },
    ...i18nMapping.map((item) => ({
      rel: "alternate",
      href: generator({ language: item.code }),
      hreflang: item.locale,
    })),
  ];

  return links.map((item) => ({
    ...item,
    href: `${baseUrl}${item.href}`,
  }));
};

/**
 * Formats a URL component by trimming, converting to lowercase, and replacing or removing certain characters.
 *
 * The function performs the following transformations:
 * - Trims leading and trailing whitespace.
 * - Converts the string to lowercase.
 * - Replaces " - " with "-".
 * - Replaces spaces with "-".
 * - Replaces "/" with "-".
 * - Replaces "(" with "-".
 * - Removes ")".
 * - Replaces "（" with "-".
 * - Removes "）".
 * - Replaces ", " with "-".
 * - Replaces "_" with "-".
 * - Replaces multiple "-" with a single "-".
 * - Removes trailing "-".
 *
 * Additionally, if the page type is `PageType.VENDOR_ONLY`, it replaces "." with "-".
 * If the page type is either `PageType.VENDOR_ONLY` or `PageType.VENDOR_IN_COUNTRY`, it normalizes the string using NFC.
 *
 * @param component - The URL component to format.
 * @param overridePageType - The page type to use for formatting.
 * @returns The formatted URL component.
 */
export function formatUrlComponent(
  component: string,
  overridePageType?: PageType,
): string {
  if (!component) {
    return "";
  }

  let formattedComponent = component.trim().toLowerCase();

  formattedComponent = formattedComponent
    .replace(/ - /g, "-") // Replace " - " with "-"
    .replace(/ /g, "-") // Replace spaces with "-"
    .replace(/\//g, "-") // Replace "/" with "-"
    .replace(/\(/g, "-") // Replace "(" with "-"
    .replace(/\)/g, "") // Remove ")"
    .replace(/（/g, "-") // Replace "（" with "-"
    .replace(/）/g, "") // Remove "）"
    .replace(/, /g, "-") // Replace ", " with "-"
    .replace(/_/g, "-") // Replace "_" with "-"
    .replace(/-+/g, "-") // Replace multiple "-" with a single "-"
    .replace(/-$/, ""); // Remove trailing "-"

  let pageType: PageType | undefined = overridePageType;
  if (!pageType) {
    const [err, tPageType] = tryit(injectPageType)();
    if (err) {
      return formattedComponent;
    }
    pageType = tPageType;
  }

  if (pageType === PageType.VENDOR_ONLY) {
    formattedComponent = formattedComponent.replace(/\./g, "-");
  }

  if ([PageType.VENDOR_ONLY, PageType.VENDOR_IN_COUNTRY].includes(pageType)) {
    formattedComponent = formattedComponent.normalize("NFC");
  }

  return formattedComponent;
}
