export enum RegionType {
  Airport = "AIRPORT",
  Station = "TRAIN_STATION",
  District = "DISTRICT",
  City = "CITY",
  Country = "COUNTRY",
}

export enum Tristate {
  False = 0,
  True = 1,
  Intermediate = 2,
}
