import { assertParse } from "typia/lib/json";
import type { LocationSuggestion } from "~/features/search/types/location-suggestion";
import { formatName } from "~/lib/utils";
import { RegionType } from "~/types/constants";
import type { PageData } from "~/types/page-data-types";

export class LocationData {
  constructor(
    public id: string,
    public name: string,
    public type: RegionType,
    public cityName: string,
    public cityGeoId: string,
    public countryName: string,
    public countryGeoId: string,
    public meta: { iata?: string } = {},
  ) {}

  static fromSuggestion(suggestion: LocationSuggestion) {
    const safeRegionName = suggestion.key_name
      .replace(`, ${suggestion.name}`, "")
      .replace(`, ${suggestion.countryName}`, "")
      .replace(` (${suggestion.iata})`, "")
      .trim();

    return new LocationData(
      suggestion.key_id,
      safeRegionName,
      suggestion.region_type ?? RegionType.City,
      suggestion.name,
      suggestion.parent_geo_id ?? "",
      suggestion.countryName,
      suggestion.geo_id ?? "",
      { iata: suggestion.iata ?? undefined },
    );
  }

  static fromRegionInfo(regionInfo: PageData<PageType>["regionInfo"]) {
    const safeRegionName = (regionInfo.name ?? regionInfo.full_name)
      .replace(`, ${regionInfo.city}`, "")
      .replace(`, ${regionInfo.country}`, "")
      .replace(` (${regionInfo.iata})`, "")
      .trim();

    return new LocationData(
      regionInfo.hashcode,
      safeRegionName,
      regionInfo.type,
      regionInfo.city,
      regionInfo.city_geoid,
      regionInfo.country,
      regionInfo.country_geoid,
      { iata: regionInfo.iata ?? undefined },
    );
  }

  toSuggestion(): LocationSuggestion {
    return {
      hashCode: this.id,
      key_id: this.id,
      key_name: this.name,
      key_state: null,
      key_region: this.name,
      key_is_airport: false,
      countryName: this.countryName,
      name: this.cityName,
      iata: this.meta.iata ?? null,
      version: "1",
      parent_geo_id: this.cityGeoId,
      geo_id: this.countryGeoId,
      region_type: this.type,
    };
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      cityName: this.cityName,
      cityGeoId: this.cityGeoId,
      countryName: this.countryName,
      countryGeoId: this.countryGeoId,
      meta: this.meta,
    };
  }

  static fromJSON(jsonStr: string) {
    const json = assertParse<ReturnType<LocationData["toJSON"]>>(jsonStr);
    return new LocationData(
      json.id,
      json.name,
      json.type,
      json.cityName,
      json.cityGeoId,
      json.countryName,
      json.countryGeoId,
      json.meta,
    );
  }

  get fullName() {
    if (this.type === RegionType.Airport) {
      if (this.name.includes("Airport")) {
        return `${this.name}, ${this.meta.iata}`;
      }
      return `${this.name} Airport, ${this.meta.iata}`;
    }

    // we use Set to remove duplicates
    return [...new Set([this.name, this.cityName, this.countryName])].join(
      ", ",
    );
  }

  toSearchableString() {
    return this.fullName;
  }

  toSlug(_targetPageType?: PageType) {
    // slug format for different types:
    // vendor airport: name-iata
    // vendor city: name
    // vendor district: name-city
    // vendor station: name
    // airport only: name
    // city only: name
    // district only: name
    // if targetPageType is not provided, we assume it's a vendor page
    return formatName(this.name).replaceAll(",", "");
  }
}
