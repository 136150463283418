// Generated by @nuxtjs/svg-sprite
export const icons = [
  "car/commercial",
  "car/large",
  "car/luxury",
  "car/medium",
  "car/other",
  "car/people-carrier",
  "car/pickup",
  "car/small",
  "car/sports",
  "car/suv",
  "car/truck",
  "common/city",
  "common/map-pin",
  "common/map",
  "common/plane",
  "common/train"
]
