import type pino from "pino";
import type { BaseLogger } from "pino";

class FallbackLogger implements BaseLogger {
  level: pino.LevelWithSilentOrString;

  constructor() {
    this.level = "";
  }

  trace(obj: unknown, msg?: string, ...args: any[]) {
    // eslint-disable-next-line no-console
    console.debug(msg, obj, ...args);
  }
  debug(obj: unknown, msg?: string, ...args: any[]) {
    // eslint-disable-next-line no-console
    console.debug(msg, obj, ...args);
  }
  info(obj: unknown, msg?: string, ...args: any[]) {
    // eslint-disable-next-line no-console
    console.info(msg, obj, ...args);
  }
  warn(obj: unknown, msg?: string, ...args: any[]) {
    console.warn(msg, obj, ...args);
  }
  error(obj: unknown, msg?: string, ...args: any[]) {
    console.error(msg, obj, ...args);
  }
  fatal(obj: unknown, msg?: string, ...args: any[]) {
    console.error(msg, obj, ...args);
  }
  silent() {}

  child(_bindings: Record<string, unknown>) {
    return this;
  }
}

export const useLogger = (context?: string): BaseLogger => {
  const logger = useRequestEvent?.()?.node.req.log ?? new FallbackLogger();

  return context ? logger.child({ context }) : logger;
};
