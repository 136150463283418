import revive_payload_client_dsmtWe1WAf from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mfiFt2W0Pk from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_uVVKVxJfHD from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_23Kzf9Fm4m from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SAOpmM0sBR from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5PEK3xj81l from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_CCKUIdAsKJ from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@9.11.0_jiti@1.21_dvocfznon5jdijvo7umeo3hedq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/usr/src/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_r4TOZNHWkB from "/usr/src/app/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.6.2/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import switch_locale_path_ssr_EUnJuyTq7b from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.4_magicast@0.3.5_rollup@4.21.2_vue@3.5.7_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_j7lFLHZykZ from "/usr/src/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.4_magicast@0.3.5_rollup@4.21.2_vue@3.5.7_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_6Ph1iQzNHk from "/usr/src/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
export default [
  revive_payload_client_dsmtWe1WAf,
  unhead_mfiFt2W0Pk,
  router_uVVKVxJfHD,
  navigation_repaint_client_23Kzf9Fm4m,
  check_outdated_build_client_SAOpmM0sBR,
  chunk_reload_client_5PEK3xj81l,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CCKUIdAsKJ,
  plugin_wy0B721ODc,
  plugin_r4TOZNHWkB,
  switch_locale_path_ssr_EUnJuyTq7b,
  i18n_j7lFLHZykZ,
  plugin_6Ph1iQzNHk
]