import { default as icons_45pageZCDdWtV3RMMeta } from "/usr/src/app/node_modules/.pnpm/@gvade+nuxt3-svg-sprite@1.0.3_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@gvade/nuxt3-svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as abouteZMWKFsTTEMeta } from "/usr/src/app/src/pages/about.vue?macro=true";
import { default as _91stationId_93nLHlZfCmfbMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-from-train-station/[stationName]/[stationId].vue?macro=true";
import { default as _91airportCode_93oBv7aV2XKMMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-from/[airportName]/[airportCode].vue?macro=true";
import { default as _91countryId_93mK1Hl7m2blMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-in-country/[countryName]/[countryId].vue?macro=true";
import { default as _91cityId_93hYXod8wO7RMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-in/[cityName]/[cityId].vue?macro=true";
import { default as _91districtId_93ow6IAXgKLoMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-neighborhood/[districtName]/[districtId].vue?macro=true";
import { default as _91vendorId_93jlDqPHdxffMeta } from "/usr/src/app/src/pages/car/[language]/car-hire-with-provider/[vendorName]/[vendorId].vue?macro=true";
import { default as indexXODioWAXD9Meta } from "/usr/src/app/src/pages/car/[language]/index.vue?macro=true";
import { default as _91vendorName_93_45car_45hire_45_91airportNameAndIata_93NAn7LDnSyBMeta } from "/usr/src/app/src/pages/car/[language]/provider-at-airport/[vendorId]/[vendorName]-car-hire-[airportNameAndIata].vue?macro=true";
import { default as _91vendorName_93_45car_45hire_45_91stationName_938OvLax2iuXMeta } from "/usr/src/app/src/pages/car/[language]/provider-at-station/[vendorId]/[vendorName]-car-hire-[stationName].vue?macro=true";
import { default as _91vendorName_93_45car_45hire_45_91city_93NehE4BkkkoMeta } from "/usr/src/app/src/pages/car/[language]/provider-in-city/[vendorId]/[vendorName]-car-hire-[city].vue?macro=true";
import { default as _91vendorName_93_45car_45hire_45_91countryName_93smzputvJ4NMeta } from "/usr/src/app/src/pages/car/[language]/provider-in-country/[vendorId]/[vendorName]-car-hire-[countryName].vue?macro=true";
import { default as _91vendorName_93_45car_45hire_45_91stationName_93KzniSqhvqwMeta } from "/usr/src/app/src/pages/car/[language]/provider-in-neighborhood/[vendorId]/[vendorName]-car-hire-[stationName].vue?macro=true";
import { default as _91type_93HegXyHNUyLMeta } from "/usr/src/app/src/pages/car/[language]/sitemaps/[type].vue?macro=true";
import { default as resultzIFBfZsZbnMeta } from "/usr/src/app/src/pages/car/result.vue?macro=true";
import { default as contactN8qrkICjM3Meta } from "/usr/src/app/src/pages/contact.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as imagesOVgl8O4gETMeta } from "/usr/src/app/src/pages/nop/images.vue?macro=true";
import { default as company8dKGNluYiEMeta } from "/usr/src/app/src/pages/policy/company.vue?macro=true";
import { default as cookie8UfXdKmVGHMeta } from "/usr/src/app/src/pages/policy/cookie.vue?macro=true";
import { default as private7RKGCR4ZeAMeta } from "/usr/src/app/src/pages/policy/private.vue?macro=true";
import { default as serviceaWXyzW3Qg4Meta } from "/usr/src/app/src/pages/policy/service.vue?macro=true";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/usr/src/app/node_modules/.pnpm/@gvade+nuxt3-svg-sprite@1.0.3_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@gvade/nuxt3-svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "about",
    path: "/about",
    meta: abouteZMWKFsTTEMeta || {},
    component: () => import("/usr/src/app/src/pages/about.vue")
  },
  {
    name: "car-language-car-hire-from-train-station-stationName-stationId",
    path: "/car/:language()/car-hire-from-train-station/:stationName()/:stationId()",
    meta: _91stationId_93nLHlZfCmfbMeta || {},
    alias: ["/car/:language/car-rental-from-train-station/:stationName/:stationId"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-from-train-station/[stationName]/[stationId].vue")
  },
  {
    name: "car-language-car-hire-from-airportName-airportCode",
    path: "/car/:language()/car-hire-from/:airportName()/:airportCode()",
    meta: _91airportCode_93oBv7aV2XKMMeta || {},
    alias: ["/car/:language/car-rental-from/:airportName/:airportCode"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-from/[airportName]/[airportCode].vue")
  },
  {
    name: "car-language-car-hire-in-country-countryName-countryId",
    path: "/car/:language()/car-hire-in-country/:countryName()/:countryId()",
    meta: _91countryId_93mK1Hl7m2blMeta || {},
    alias: ["/car/:language/car-rental-in-country/:countryName/:countryId","/car/uk/car-rental-in/:countryName/:countryId"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-in-country/[countryName]/[countryId].vue")
  },
  {
    name: "car-language-car-hire-in-cityName-cityId",
    path: "/car/:language()/car-hire-in/:cityName()/:cityId()",
    meta: _91cityId_93hYXod8wO7RMeta || {},
    alias: ["/car/:language/car-rental-in/:cityName/:cityId"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-in/[cityName]/[cityId].vue")
  },
  {
    name: "car-language-car-hire-neighborhood-districtName-districtId",
    path: "/car/:language()/car-hire-neighborhood/:districtName()/:districtId()",
    meta: _91districtId_93ow6IAXgKLoMeta || {},
    alias: ["/car/:language/car-rental-neighborhood/:districtName/:districtId"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-neighborhood/[districtName]/[districtId].vue")
  },
  {
    name: "car-language-car-hire-with-provider-vendorName-vendorId",
    path: "/car/:language()/car-hire-with-provider/:vendorName()/:vendorId()",
    meta: _91vendorId_93jlDqPHdxffMeta || {},
    alias: ["/car/:language/car-rental-with-provider/:vendorName/:vendorId"],
    component: () => import("/usr/src/app/src/pages/car/[language]/car-hire-with-provider/[vendorName]/[vendorId].vue")
  },
  {
    name: "car-language",
    path: "/car/:language()",
    meta: indexXODioWAXD9Meta || {},
    component: () => import("/usr/src/app/src/pages/car/[language]/index.vue")
  },
  {
    name: "car-language-provider-at-airport-vendorId-vendorName-car-hire-airportNameAndIata",
    path: "/car/:language()/provider-at-airport/:vendorId()/:vendorName()-car-hire-:airportNameAndIata()",
    meta: _91vendorName_93_45car_45hire_45_91airportNameAndIata_93NAn7LDnSyBMeta || {},
    alias: ["/car/:language/provider-at-airport/:vendorId/:vendorName-car-rental-:airportNameAndIata"],
    component: () => import("/usr/src/app/src/pages/car/[language]/provider-at-airport/[vendorId]/[vendorName]-car-hire-[airportNameAndIata].vue")
  },
  {
    name: "car-language-provider-at-station-vendorId-vendorName-car-hire-stationName",
    path: "/car/:language()/provider-at-station/:vendorId()/:vendorName()-car-hire-:stationName()",
    meta: _91vendorName_93_45car_45hire_45_91stationName_938OvLax2iuXMeta || {},
    alias: ["/car/:language/provider-at-station/:vendorId/:vendorName-car-rental-:stationName"],
    component: () => import("/usr/src/app/src/pages/car/[language]/provider-at-station/[vendorId]/[vendorName]-car-hire-[stationName].vue")
  },
  {
    name: "car-language-provider-in-city-vendorId-vendorName-car-hire-city",
    path: "/car/:language()/provider-in-city/:vendorId()/:vendorName()-car-hire-:city()",
    meta: _91vendorName_93_45car_45hire_45_91city_93NehE4BkkkoMeta || {},
    alias: ["/car/:language/provider-in-city/:vendorId/:vendorName-car-rental-:city"],
    component: () => import("/usr/src/app/src/pages/car/[language]/provider-in-city/[vendorId]/[vendorName]-car-hire-[city].vue")
  },
  {
    name: "car-language-provider-in-country-vendorId-vendorName-car-hire-countryName",
    path: "/car/:language()/provider-in-country/:vendorId()/:vendorName()-car-hire-:countryName()",
    meta: _91vendorName_93_45car_45hire_45_91countryName_93smzputvJ4NMeta || {},
    alias: ["/car/:language/provider-in-country/:vendorId/:vendorName-car-rental-:countryName"],
    component: () => import("/usr/src/app/src/pages/car/[language]/provider-in-country/[vendorId]/[vendorName]-car-hire-[countryName].vue")
  },
  {
    name: "car-language-provider-in-neighborhood-vendorId-vendorName-car-hire-stationName",
    path: "/car/:language()/provider-in-neighborhood/:vendorId()/:vendorName()-car-hire-:stationName()",
    meta: _91vendorName_93_45car_45hire_45_91stationName_93KzniSqhvqwMeta || {},
    alias: ["/car/:language/provider-in-neighborhood/:vendorId/:vendorName-car-rental-:stationName"],
    component: () => import("/usr/src/app/src/pages/car/[language]/provider-in-neighborhood/[vendorId]/[vendorName]-car-hire-[stationName].vue")
  },
  {
    name: "car-language-sitemaps-type",
    path: "/car/:language()/sitemaps/:type()",
    component: () => import("/usr/src/app/src/pages/car/[language]/sitemaps/[type].vue")
  },
  {
    name: "car-result",
    path: "/car/result",
    meta: resultzIFBfZsZbnMeta || {},
    alias: ["/car/result"],
    component: () => import("/usr/src/app/src/pages/car/result.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactN8qrkICjM3Meta || {},
    component: () => import("/usr/src/app/src/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/src/pages/index.vue")
  },
  {
    name: "nop-images",
    path: "/nop/images",
    component: () => import("/usr/src/app/src/pages/nop/images.vue")
  },
  {
    name: "policy-company",
    path: "/policy/company",
    meta: company8dKGNluYiEMeta || {},
    component: () => import("/usr/src/app/src/pages/policy/company.vue")
  },
  {
    name: "policy-cookie",
    path: "/policy/cookie",
    meta: cookie8UfXdKmVGHMeta || {},
    component: () => import("/usr/src/app/src/pages/policy/cookie.vue")
  },
  {
    name: "policy-private",
    path: "/policy/private",
    meta: private7RKGCR4ZeAMeta || {},
    component: () => import("/usr/src/app/src/pages/policy/private.vue")
  },
  {
    name: "policy-service",
    path: "/policy/service",
    meta: serviceaWXyzW3Qg4Meta || {},
    component: () => import("/usr/src/app/src/pages/policy/service.vue")
  }
]