import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatUrlComponent } from "~/utils/seo";

export function jump2ResultsPage() {}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function capitalizedWords(sentence: string, splitBy: string = "-") {
  if (sentence) {
    const words = sentence.split(splitBy);
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );
    const capitalizedSentence = capitalizedWords.join(" ");
    return capitalizedSentence;
  }
  return "";
}

export function objectToQueryString(obj: any) {
  const params: string[] = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return params.join("&");
}

export function getDistinctPropertyValues<T>(
  arr: T[],
  property: keyof T,
): T[keyof T][] {
  const distinctValues: T[keyof T][] = [];
  const valueSet = new Set<T[keyof T]>();

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const value = obj[property];

    if (!valueSet.has(value)) {
      valueSet.add(value);
      distinctValues.push(value);
    }
  }

  return distinctValues;
}

/**
 * Formats a given name by applying URL component formatting.
 *
 * @param name - The name to be formatted.
 * @returns The formatted name as a URL component.
 *
 * @deprecated Use `formatUrlComponent` instead.
 */
export function formatName(name: string) {
  return formatUrlComponent(name);
}

export function formatLink(name: string) {
  if (!name) return "";
  return name
    .trim()
    .toLowerCase()
    .replace(/[\s\-,/_()（）]+/g, "-") // 将空格、逗号、斜杠、下划线、括号等替换为一个短横线
    .replace(/-+/g, "-") // 合并多余的短横线为一个
    .replace(/^-|-$/g, ""); // 去除开头和结尾的短横线
}

export function formatStationName(name: string) {
  if (!name) return "";
  const strList = name.split(" ");
  strList.forEach((str, index) => {
    strList[index] = lowerCaseFirstLetter(strList[index]);
  });

  return strList.join("-");
}

export function lowerCaseFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const i18nMapping = [
  {
    code: "uk",
    currency: "GBP",
    symbol: "£",
    region: "United Kingdom",
    locale: "en-GB",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "us",
    currency: "USD",
    symbol: "$",
    region: "United States",
    locale: "en-US",
    usOrUK: "en-US",
    language: "English",
  },

  {
    code: "ca",
    currency: "CAD",
    symbol: "C$",
    region: "Canada",
    locale: "en-CA",
    usOrUK: "en-US",
    language: "English",
  },
  {
    code: "au",
    currency: "AUD",
    symbol: "A$",
    region: "Australia",
    locale: "en-AU",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "in",
    currency: "INR",
    symbol: "₹",
    region: "India",
    locale: "en-IN",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "hi-IN",
  },
  {
    code: "sg",
    currency: "SGD",
    symbol: "S$",
    region: "Singapore",
    locale: "en-SG",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "ms-SG,zh-SG",
  },
  {
    code: "ie",
    currency: "EUR",
    symbol: "€",
    region: "Ireland",
    locale: "en-IE",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "nz",
    currency: "NZD",
    symbol: "NZ$",
    region: "New Zealand",
    locale: "en-NZ",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "ph",
    currency: "PHP",
    symbol: "₱",
    region: "Philippines",
    locale: "en-PH",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "tl-PH",
  },
  {
    code: "my",
    currency: "MYR",
    symbol: "RM",
    region: "Malaysia",
    locale: "en-MY",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "ms-MY, zh-MY",
  },
  {
    code: "za",
    currency: "ZAR",
    symbol: "R",
    region: "South Africa",
    locale: "en-ZA",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "ae",
    currency: "AED",
    symbol: "د.إ",
    region: "United Arab Emirates",
    locale: "en-AE",
    usOrUK: "en-US",
    language: "English",
    otherLocales: "ar-AE",
  },
  {
    code: "sa",
    currency: "SAR",
    symbol: "﷼",
    region: "Saudi Arabia",
    locale: "en-SA",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "ar-SA",
  },
  {
    code: "qa",
    currency: "QAR",
    symbol: "﷼",
    region: "Qatar",
    locale: "en-QA",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "ar-QA",
  },
  {
    code: "hk",
    currency: "HKD",
    symbol: "HK$",
    region: "Hong Kong",
    locale: "en-HK",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "zh-HK",
  },
  {
    code: "ke",
    currency: "KES",
    symbol: "KSh",
    region: "Kenya",
    locale: "en-KE",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "ng",
    currency: "NGN",
    symbol: "₦",
    region: "Nigeria",
    locale: "en-NG",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "eg",
    currency: "EGP",
    symbol: "E£",
    region: "Egypt",
    locale: "en-EG",
    usOrUK: "en-GB",
    language: "English",
    otherLocales: "ar-EG",
  },
  {
    code: "pk",
    currency: "PKR",
    symbol: "₨",
    region: "Pakistan",
    locale: "en-PK",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "gh",
    currency: "GHS",
    symbol: "GH₵",
    region: "Ghana",
    locale: "en-GH",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "tz",
    currency: "TZS",
    symbol: "TSh",
    region: "Tanzania",
    locale: "en-TZ",
    usOrUK: "en-GB",
    language: "English",
  },
  {
    code: "il",
    currency: "ILS",
    symbol: "₪",
    region: "Israel",
    locale: "en-IL",
    usOrUK: "en-US",
    language: "English",
    otherLocales: "he-IL",
  },
];

export const $apiFetch = $fetch.create({
  baseURL: "https://www.voyagecopilot.com/api/",
  onRequest({ request, options }) {
    // @ts-expect-error adding startTime to options for performance logging
    options.startTime = Date.now();
    const method = options.method ?? "GET";
    useLogger("api").trace(
      { method, request, query: options.query },
      "Initiating outbound request",
    );
  },
  onRequestError({ request, error }) {
    useLogger("api").error(
      { request, error },
      "Error sending data to outbound request",
    );
  },
  onResponse({ request, options }) {
    // @ts-expect-error startTime is added to options for performance logging
    const duration = Date.now() - options.startTime;
    useLogger("api").trace({ request, duration }, "Completed outbound request");
  },
  onResponseError({ request, response }) {
    useLogger("api").error(
      { request, response },
      "Error fetching data from outbound request",
    );
  },
});

export const useApiFetch: typeof useFetch = (request, options?) => {
  const defaultOptions: typeof options = {
    deep: false,
    $fetch: $apiFetch,
  };
  return useFetch(request, { ...defaultOptions, ...options });
};

export function getFirstItem(input: string | string[]): string {
  if (Array.isArray(input)) {
    return input[0];
  }
  return input;
}

export function closeCurrentDialog(currentElement: HTMLElement) {
  // find the nearest role="dialog" in parent elements
  const dialogElement = currentElement.closest('[role="dialog"]');
  const dialogId = dialogElement?.id;
  if (dialogId) {
    const trigger = document.querySelector(`[aria-controls="${dialogId}"]`);
    trigger?.dispatchEvent(new MouseEvent("click", { bubbles: true }));
  }
}

export function getUrlFavicon(
  url: string,
  size: number = 32,
  fullUrl: boolean = false,
) {
  const urlObj = new URL(url);
  if (fullUrl) {
    return `https://www.google.com/s2/favicons?domain=${urlObj.href}&sz=${size}`;
  }
  return `https://www.google.com/s2/favicons?domain=${urlObj.host}&sz=${size}`;
}
