import type { InjectionKey } from "vue";
import type { PageData } from "./page-data-types";
import { LocationData } from "~/data/LocationData";

export const RouteParamsInjectionKey = Symbol("RouteParams") as InjectionKey<{
  language: string;
}>;

export const PageTypeInjectionKey = Symbol(
  "PageType",
) as InjectionKey<PageType>;

const pageDataKeys: {
  [key: PageType[number]]: InjectionKey<PageData<PageType>>;
} = {};

export function getPageDataInjectionKey<T extends PageType>(
  pageType: T,
): InjectionKey<PageData<T>> {
  if (!pageDataKeys[pageType]) {
    pageDataKeys[pageType] = Symbol(`PageData-${pageType}`) as InjectionKey<
      PageData<T>
    >;
  }
  return pageDataKeys[pageType];
}

export const RegionInfoInjectionKey = Symbol(
  "RegionInfo",
) as InjectionKey<LocationData>;

export function injectPageType() {
  const value = inject(PageTypeInjectionKey, null);
  if (!value) {
    throw createError("PageType not provided");
  }
  return value;
}

export function injectPageData<T extends PageType>(pageType: T): PageData<T> {
  const value = inject(getPageDataInjectionKey(pageType), null);
  if (!value) {
    throw createError(`PageData not provided for ${pageType}`);
  }
  return value;
}

export function injectRouteParams() {
  const value = inject(RouteParamsInjectionKey, null);
  if (!value) {
    throw createError("RouteParams not provided");
  }
  return value;
}

export function injectRegionInfo(must = true) {
  const value = inject(RegionInfoInjectionKey, null);
  if (!value) {
    try {
      const { regionInfo } = injectPageData(injectPageType());
      return LocationData.fromRegionInfo(regionInfo);
    } catch (e) {
      if (must) {
        throw createError(
          "RegionInfo not provided, and cannot be inferred from pageData",
        );
      }
      return null;
    }
  }
  return value;
}
