import { i18nMapping } from "~/lib/utils";

export function getLocale(language: string): "en-GB" | "en-US" {
  const curLangInfo = i18nMapping.filter((item) => item.code === language);
  if (curLangInfo.length) {
    if (curLangInfo[0].usOrUK === "en-US") {
      return "en-US";
    }
  }
  return "en-GB";
}

export function getLanguageFromLocale(locale: string) {
  const curLangInfo = i18nMapping.filter((item) => item.locale === locale);
  if (curLangInfo.length) {
    return curLangInfo[0].code;
  }
  return "uk";
}
