
// @ts-nocheck


export const localeCodes =  [
  "us",
  "il",
  "ae",
  "ca",
  "uk",
  "au",
  "in",
  "sg",
  "ie",
  "nz",
  "ph",
  "my",
  "za",
  "sa",
  "qa",
  "hk",
  "ke",
  "ng",
  "eg",
  "pk",
  "gh",
  "tz"
]

export const localeLoaders = {
  "us": [{ key: "../src/lang/us/index.json", load: () => import("../src/lang/us/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_index_json" */), cache: true },
{ key: "../src/lang/us/about.json", load: () => import("../src/lang/us/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/us/providersAtAirport.json", load: () => import("../src/lang/us/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/us/providersFromStation.json", load: () => import("../src/lang/us/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersFromStation_json" */), cache: true },
{ key: "../src/lang/us/providersInCity.json", load: () => import("../src/lang/us/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCity_json" */), cache: true },
{ key: "../src/lang/us/providersInCountry.json", load: () => import("../src/lang/us/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCountry_json" */), cache: true },
{ key: "../src/lang/us/providersInNeighborhood.json", load: () => import("../src/lang/us/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/us/startPlanning.json", load: () => import("../src/lang/us/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_startPlanning_json" */), cache: true },
{ key: "../src/lang/us/airportOnly.json", load: () => import("../src/lang/us/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_airportOnly_json" */), cache: true },
{ key: "../src/lang/us/stationOnly.json", load: () => import("../src/lang/us/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_stationOnly_json" */), cache: true },
{ key: "../src/lang/us/districtOnly.json", load: () => import("../src/lang/us/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_districtOnly_json" */), cache: true },
{ key: "../src/lang/us/cityOnly.json", load: () => import("../src/lang/us/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_cityOnly_json" */), cache: true },
{ key: "../src/lang/us/countryOnly.json", load: () => import("../src/lang/us/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_countryOnly_json" */), cache: true },
{ key: "../src/lang/us/vendorInCountry.json", load: () => import("../src/lang/us/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/us/vendorOnly.json", load: () => import("../src/lang/us/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorOnly_json" */), cache: true },
{ key: "../src/lang/us/sitemap.json", load: () => import("../src/lang/us/sitemap.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_sitemap_json" */), cache: true },
{ key: "../src/lang/us/home.json", load: () => import("../src/lang/us/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_home_json" */), cache: true }],
  "il": [{ key: "../src/lang/us/index.json", load: () => import("../src/lang/us/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_index_json" */), cache: true },
{ key: "../src/lang/us/about.json", load: () => import("../src/lang/us/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/us/providersAtAirport.json", load: () => import("../src/lang/us/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/us/providersFromStation.json", load: () => import("../src/lang/us/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersFromStation_json" */), cache: true },
{ key: "../src/lang/us/providersInCity.json", load: () => import("../src/lang/us/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCity_json" */), cache: true },
{ key: "../src/lang/us/providersInCountry.json", load: () => import("../src/lang/us/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCountry_json" */), cache: true },
{ key: "../src/lang/us/providersInNeighborhood.json", load: () => import("../src/lang/us/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/us/startPlanning.json", load: () => import("../src/lang/us/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_startPlanning_json" */), cache: true },
{ key: "../src/lang/us/airportOnly.json", load: () => import("../src/lang/us/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_airportOnly_json" */), cache: true },
{ key: "../src/lang/us/stationOnly.json", load: () => import("../src/lang/us/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_stationOnly_json" */), cache: true },
{ key: "../src/lang/us/districtOnly.json", load: () => import("../src/lang/us/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_districtOnly_json" */), cache: true },
{ key: "../src/lang/us/cityOnly.json", load: () => import("../src/lang/us/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_cityOnly_json" */), cache: true },
{ key: "../src/lang/us/countryOnly.json", load: () => import("../src/lang/us/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_countryOnly_json" */), cache: true },
{ key: "../src/lang/us/vendorInCountry.json", load: () => import("../src/lang/us/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/us/vendorOnly.json", load: () => import("../src/lang/us/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorOnly_json" */), cache: true },
{ key: "../src/lang/us/sitemap.json", load: () => import("../src/lang/us/sitemap.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_sitemap_json" */), cache: true },
{ key: "../src/lang/us/home.json", load: () => import("../src/lang/us/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_home_json" */), cache: true }],
  "ae": [{ key: "../src/lang/us/index.json", load: () => import("../src/lang/us/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_index_json" */), cache: true },
{ key: "../src/lang/us/about.json", load: () => import("../src/lang/us/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/us/providersAtAirport.json", load: () => import("../src/lang/us/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/us/providersFromStation.json", load: () => import("../src/lang/us/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersFromStation_json" */), cache: true },
{ key: "../src/lang/us/providersInCity.json", load: () => import("../src/lang/us/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCity_json" */), cache: true },
{ key: "../src/lang/us/providersInCountry.json", load: () => import("../src/lang/us/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCountry_json" */), cache: true },
{ key: "../src/lang/us/providersInNeighborhood.json", load: () => import("../src/lang/us/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/us/startPlanning.json", load: () => import("../src/lang/us/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_startPlanning_json" */), cache: true },
{ key: "../src/lang/us/airportOnly.json", load: () => import("../src/lang/us/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_airportOnly_json" */), cache: true },
{ key: "../src/lang/us/stationOnly.json", load: () => import("../src/lang/us/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_stationOnly_json" */), cache: true },
{ key: "../src/lang/us/districtOnly.json", load: () => import("../src/lang/us/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_districtOnly_json" */), cache: true },
{ key: "../src/lang/us/cityOnly.json", load: () => import("../src/lang/us/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_cityOnly_json" */), cache: true },
{ key: "../src/lang/us/countryOnly.json", load: () => import("../src/lang/us/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_countryOnly_json" */), cache: true },
{ key: "../src/lang/us/vendorInCountry.json", load: () => import("../src/lang/us/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/us/vendorOnly.json", load: () => import("../src/lang/us/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorOnly_json" */), cache: true },
{ key: "../src/lang/us/sitemap.json", load: () => import("../src/lang/us/sitemap.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_sitemap_json" */), cache: true },
{ key: "../src/lang/us/home.json", load: () => import("../src/lang/us/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_home_json" */), cache: true }],
  "ca": [{ key: "../src/lang/us/index.json", load: () => import("../src/lang/us/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_index_json" */), cache: true },
{ key: "../src/lang/us/about.json", load: () => import("../src/lang/us/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/us/providersAtAirport.json", load: () => import("../src/lang/us/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/us/providersFromStation.json", load: () => import("../src/lang/us/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersFromStation_json" */), cache: true },
{ key: "../src/lang/us/providersInCity.json", load: () => import("../src/lang/us/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCity_json" */), cache: true },
{ key: "../src/lang/us/providersInCountry.json", load: () => import("../src/lang/us/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInCountry_json" */), cache: true },
{ key: "../src/lang/us/providersInNeighborhood.json", load: () => import("../src/lang/us/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/us/startPlanning.json", load: () => import("../src/lang/us/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_startPlanning_json" */), cache: true },
{ key: "../src/lang/us/airportOnly.json", load: () => import("../src/lang/us/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_airportOnly_json" */), cache: true },
{ key: "../src/lang/us/stationOnly.json", load: () => import("../src/lang/us/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_stationOnly_json" */), cache: true },
{ key: "../src/lang/us/districtOnly.json", load: () => import("../src/lang/us/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_districtOnly_json" */), cache: true },
{ key: "../src/lang/us/cityOnly.json", load: () => import("../src/lang/us/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_cityOnly_json" */), cache: true },
{ key: "../src/lang/us/countryOnly.json", load: () => import("../src/lang/us/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_countryOnly_json" */), cache: true },
{ key: "../src/lang/us/vendorInCountry.json", load: () => import("../src/lang/us/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/us/vendorOnly.json", load: () => import("../src/lang/us/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_vendorOnly_json" */), cache: true },
{ key: "../src/lang/us/sitemap.json", load: () => import("../src/lang/us/sitemap.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_sitemap_json" */), cache: true },
{ key: "../src/lang/us/home.json", load: () => import("../src/lang/us/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_us_home_json" */), cache: true }],
  "uk": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "au": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "in": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "sg": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "ie": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "nz": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "ph": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "my": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "za": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "sa": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "qa": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "hk": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "ke": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "ng": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "eg": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "pk": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "gh": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }],
  "tz": [{ key: "../src/lang/uk/index.json", load: () => import("../src/lang/uk/index.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_index_json" */), cache: true },
{ key: "../src/lang/uk/about.json", load: () => import("../src/lang/uk/about.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_about_json" */), cache: true },
{ key: "../src/lang/uk/common.json", load: () => import("../src/lang/uk/common.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_common_json" */), cache: true },
{ key: "../src/lang/uk/providersAtAirport.json", load: () => import("../src/lang/uk/providersAtAirport.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersAtAirport_json" */), cache: true },
{ key: "../src/lang/uk/providersFromStation.json", load: () => import("../src/lang/uk/providersFromStation.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersFromStation_json" */), cache: true },
{ key: "../src/lang/uk/providersInCity.json", load: () => import("../src/lang/uk/providersInCity.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCity_json" */), cache: true },
{ key: "../src/lang/uk/providersInCountry.json", load: () => import("../src/lang/uk/providersInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInCountry_json" */), cache: true },
{ key: "../src/lang/uk/providersInNeighborhood.json", load: () => import("../src/lang/uk/providersInNeighborhood.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_providersInNeighborhood_json" */), cache: true },
{ key: "../src/lang/uk/startPlanning.json", load: () => import("../src/lang/uk/startPlanning.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_startPlanning_json" */), cache: true },
{ key: "../src/lang/uk/airportOnly.json", load: () => import("../src/lang/uk/airportOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_airportOnly_json" */), cache: true },
{ key: "../src/lang/uk/stationOnly.json", load: () => import("../src/lang/uk/stationOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_stationOnly_json" */), cache: true },
{ key: "../src/lang/uk/districtOnly.json", load: () => import("../src/lang/uk/districtOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_districtOnly_json" */), cache: true },
{ key: "../src/lang/uk/cityOnly.json", load: () => import("../src/lang/uk/cityOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_cityOnly_json" */), cache: true },
{ key: "../src/lang/uk/countryOnly.json", load: () => import("../src/lang/uk/countryOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_countryOnly_json" */), cache: true },
{ key: "../src/lang/uk/vendorInCountry.json", load: () => import("../src/lang/uk/vendorInCountry.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorInCountry_json" */), cache: true },
{ key: "../src/lang/uk/vendorOnly.json", load: () => import("../src/lang/uk/vendorOnly.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_vendorOnly_json" */), cache: true },
{ key: "../src/lang/uk/home.json", load: () => import("../src/lang/uk/home.json" /* webpackChunkName: "locale__usr_src_app_src_lang_uk_home_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "us",
      "name": "English (US)",
      "files": [
        "/usr/src/app/src/lang/us/index.json",
        "/usr/src/app/src/lang/us/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/us/providersAtAirport.json",
        "/usr/src/app/src/lang/us/providersFromStation.json",
        "/usr/src/app/src/lang/us/providersInCity.json",
        "/usr/src/app/src/lang/us/providersInCountry.json",
        "/usr/src/app/src/lang/us/providersInNeighborhood.json",
        "/usr/src/app/src/lang/us/startPlanning.json",
        "/usr/src/app/src/lang/us/airportOnly.json",
        "/usr/src/app/src/lang/us/stationOnly.json",
        "/usr/src/app/src/lang/us/districtOnly.json",
        "/usr/src/app/src/lang/us/cityOnly.json",
        "/usr/src/app/src/lang/us/countryOnly.json",
        "/usr/src/app/src/lang/us/vendorInCountry.json",
        "/usr/src/app/src/lang/us/vendorOnly.json",
        "/usr/src/app/src/lang/us/sitemap.json",
        "/usr/src/app/src/lang/us/home.json"
      ],
      "isCatchAllLocale": true,
      "language": "en-US"
    },
    {
      "code": "il",
      "name": "English (US)",
      "files": [
        "/usr/src/app/src/lang/us/index.json",
        "/usr/src/app/src/lang/us/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/us/providersAtAirport.json",
        "/usr/src/app/src/lang/us/providersFromStation.json",
        "/usr/src/app/src/lang/us/providersInCity.json",
        "/usr/src/app/src/lang/us/providersInCountry.json",
        "/usr/src/app/src/lang/us/providersInNeighborhood.json",
        "/usr/src/app/src/lang/us/startPlanning.json",
        "/usr/src/app/src/lang/us/airportOnly.json",
        "/usr/src/app/src/lang/us/stationOnly.json",
        "/usr/src/app/src/lang/us/districtOnly.json",
        "/usr/src/app/src/lang/us/cityOnly.json",
        "/usr/src/app/src/lang/us/countryOnly.json",
        "/usr/src/app/src/lang/us/vendorInCountry.json",
        "/usr/src/app/src/lang/us/vendorOnly.json",
        "/usr/src/app/src/lang/us/sitemap.json",
        "/usr/src/app/src/lang/us/home.json"
      ],
      "language": "en-IL"
    },
    {
      "code": "ae",
      "name": "English (US)",
      "files": [
        "/usr/src/app/src/lang/us/index.json",
        "/usr/src/app/src/lang/us/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/us/providersAtAirport.json",
        "/usr/src/app/src/lang/us/providersFromStation.json",
        "/usr/src/app/src/lang/us/providersInCity.json",
        "/usr/src/app/src/lang/us/providersInCountry.json",
        "/usr/src/app/src/lang/us/providersInNeighborhood.json",
        "/usr/src/app/src/lang/us/startPlanning.json",
        "/usr/src/app/src/lang/us/airportOnly.json",
        "/usr/src/app/src/lang/us/stationOnly.json",
        "/usr/src/app/src/lang/us/districtOnly.json",
        "/usr/src/app/src/lang/us/cityOnly.json",
        "/usr/src/app/src/lang/us/countryOnly.json",
        "/usr/src/app/src/lang/us/vendorInCountry.json",
        "/usr/src/app/src/lang/us/vendorOnly.json",
        "/usr/src/app/src/lang/us/sitemap.json",
        "/usr/src/app/src/lang/us/home.json"
      ],
      "language": "en-AE"
    },
    {
      "code": "ca",
      "name": "English (US)",
      "files": [
        "/usr/src/app/src/lang/us/index.json",
        "/usr/src/app/src/lang/us/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/us/providersAtAirport.json",
        "/usr/src/app/src/lang/us/providersFromStation.json",
        "/usr/src/app/src/lang/us/providersInCity.json",
        "/usr/src/app/src/lang/us/providersInCountry.json",
        "/usr/src/app/src/lang/us/providersInNeighborhood.json",
        "/usr/src/app/src/lang/us/startPlanning.json",
        "/usr/src/app/src/lang/us/airportOnly.json",
        "/usr/src/app/src/lang/us/stationOnly.json",
        "/usr/src/app/src/lang/us/districtOnly.json",
        "/usr/src/app/src/lang/us/cityOnly.json",
        "/usr/src/app/src/lang/us/countryOnly.json",
        "/usr/src/app/src/lang/us/vendorInCountry.json",
        "/usr/src/app/src/lang/us/vendorOnly.json",
        "/usr/src/app/src/lang/us/sitemap.json",
        "/usr/src/app/src/lang/us/home.json"
      ],
      "language": "en-CA"
    },
    {
      "code": "uk",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-GB"
    },
    {
      "code": "au",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-AU"
    },
    {
      "code": "in",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-IN"
    },
    {
      "code": "sg",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-SG"
    },
    {
      "code": "ie",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-IE"
    },
    {
      "code": "nz",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-NZ"
    },
    {
      "code": "ph",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-PH"
    },
    {
      "code": "my",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-MY"
    },
    {
      "code": "za",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-ZA"
    },
    {
      "code": "sa",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-SA"
    },
    {
      "code": "qa",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-QA"
    },
    {
      "code": "hk",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-HK"
    },
    {
      "code": "ke",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-KE"
    },
    {
      "code": "ng",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-NG"
    },
    {
      "code": "eg",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-EG"
    },
    {
      "code": "pk",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-PK"
    },
    {
      "code": "gh",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-GH"
    },
    {
      "code": "tz",
      "name": "English (UK)",
      "files": [
        "/usr/src/app/src/lang/uk/index.json",
        "/usr/src/app/src/lang/uk/about.json",
        "/usr/src/app/src/lang/uk/common.json",
        "/usr/src/app/src/lang/uk/providersAtAirport.json",
        "/usr/src/app/src/lang/uk/providersFromStation.json",
        "/usr/src/app/src/lang/uk/providersInCity.json",
        "/usr/src/app/src/lang/uk/providersInCountry.json",
        "/usr/src/app/src/lang/uk/providersInNeighborhood.json",
        "/usr/src/app/src/lang/uk/startPlanning.json",
        "/usr/src/app/src/lang/uk/airportOnly.json",
        "/usr/src/app/src/lang/uk/stationOnly.json",
        "/usr/src/app/src/lang/uk/districtOnly.json",
        "/usr/src/app/src/lang/uk/cityOnly.json",
        "/usr/src/app/src/lang/uk/countryOnly.json",
        "/usr/src/app/src/lang/uk/vendorInCountry.json",
        "/usr/src/app/src/lang/uk/vendorOnly.json",
        "/usr/src/app/src/lang/uk/home.json"
      ],
      "language": "en-TZ"
    }
  ],
  "defaultLocale": "uk",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "lang",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://www.voyagecopilot.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "us",
    "name": "English (US)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/us/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/sitemap.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/home.json"
      }
    ],
    "isCatchAllLocale": true,
    "language": "en-US"
  },
  {
    "code": "il",
    "name": "English (US)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/us/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/sitemap.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/home.json"
      }
    ],
    "language": "en-IL"
  },
  {
    "code": "ae",
    "name": "English (US)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/us/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/sitemap.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/home.json"
      }
    ],
    "language": "en-AE"
  },
  {
    "code": "ca",
    "name": "English (US)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/us/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/sitemap.json"
      },
      {
        "path": "/usr/src/app/src/lang/us/home.json"
      }
    ],
    "language": "en-CA"
  },
  {
    "code": "uk",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-GB"
  },
  {
    "code": "au",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-AU"
  },
  {
    "code": "in",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-IN"
  },
  {
    "code": "sg",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-SG"
  },
  {
    "code": "ie",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-IE"
  },
  {
    "code": "nz",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-NZ"
  },
  {
    "code": "ph",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-PH"
  },
  {
    "code": "my",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-MY"
  },
  {
    "code": "za",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-ZA"
  },
  {
    "code": "sa",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-SA"
  },
  {
    "code": "qa",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-QA"
  },
  {
    "code": "hk",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-HK"
  },
  {
    "code": "ke",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-KE"
  },
  {
    "code": "ng",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-NG"
  },
  {
    "code": "eg",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-EG"
  },
  {
    "code": "pk",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-PK"
  },
  {
    "code": "gh",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-GH"
  },
  {
    "code": "tz",
    "name": "English (UK)",
    "files": [
      {
        "path": "/usr/src/app/src/lang/uk/index.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/about.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/common.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersAtAirport.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersFromStation.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCity.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/providersInNeighborhood.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/startPlanning.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/airportOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/stationOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/districtOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/cityOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/countryOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorInCountry.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/vendorOnly.json"
      },
      {
        "path": "/usr/src/app/src/lang/uk/home.json"
      }
    ],
    "language": "en-TZ"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
