import { formatName } from "~/lib/utils";

export enum PageType {
  VENDOR_ONLY = "car-hire-with-provider", // car-hire-with-provider
  AIRPORT_ONLY = "car-hire-from", // car-hire-from car-rental-from
  COUNTRY_ONLY = "car-hire-in-country", // car-hire-in-country car-rental-in-country
  CITY_ONLY = "car-hire-in", // car-hire-in car-rental-in
  NEIGHBORHOOD_ONLY = "car-hire-neighborhood", // car-hire-neighborhood car-rental-neighborhood
  STATION_ONLY = "car-hire-from-train-station", // car-hire-from-train-station car-rental-from-train-station

  VENDOR_IN_CITY = "provider-in-city",
  VENDOR_AT_AIRPORT = "provider-at-airport",
  VENDOR_IN_COUNTRY = "provider-in-country",
  VENDOR_AT_STATION = "provider-at-station",
  VENDOR_NEIGHBOURHOOD = "provider-in-neighborhood",

  SITEMAP = "sitemaps",
  HOME = "home",
}

export interface PageLinkParamsMap {
  [PageType.VENDOR_ONLY]: {
    vendorId: string;
    vendorName: string;
  };
  [PageType.AIRPORT_ONLY]: {
    airportCode: string;
    airportName: string;
  };
  [PageType.COUNTRY_ONLY]: {
    countryId: string;
    countryName: string;
  };
  [PageType.CITY_ONLY]: {
    cityId: string;
    cityName: string;
  };
  [PageType.NEIGHBORHOOD_ONLY]: {
    districtId: string;
    districtName: string;
  };
  [PageType.STATION_ONLY]: {
    stationId: string;
    stationName: string;
  };

  [PageType.VENDOR_IN_CITY]: {
    vendorId: string;
    cityName: string;
    vendorName: string;
  };
  [PageType.VENDOR_AT_AIRPORT]: {
    vendorId: string;
    vendorName: string;
    airportCode: string;
    airportName: string;
  };
  [PageType.VENDOR_AT_STATION]: {
    vendorId: string;
    vendorName: string;
    stationName: string;
  };
  [PageType.VENDOR_NEIGHBOURHOOD]: {
    vendorId: string;
    vendorName: string;
    districtName: string;
    cityName: string;
  };
  [PageType.VENDOR_IN_COUNTRY]: {
    vendorId: string;
    vendorName: string;
    countryName: string;
  };

  [PageType.SITEMAP]: {
    type: "country" | "provider";
  };
  [PageType.HOME]: never;
}

interface PageLinkOptions {
  language: string;
}

function assertPageTypeParams<T extends keyof PageLinkParamsMap>(
  params: unknown,
): asserts params is PageLinkParamsMap[T] {
  // just a placeholder to satisfy the type checker,
  // and provide type hints to the developer
}

const FORCE_UK_LIST: PageType[] = [];

function buildPageLink<T extends PageType>(
  pageType: T,
  params: T extends keyof PageLinkParamsMap ? PageLinkParamsMap[T] : never,
  baseUri: string,
  hireOrRental: string,
): string {
  switch (pageType) {
    case PageType.VENDOR_ONLY:
      assertPageTypeParams<PageType.VENDOR_ONLY>(params);
      return `${baseUri}/${params.vendorName}/${params.vendorId}`;

    case PageType.AIRPORT_ONLY:
      assertPageTypeParams<PageType.AIRPORT_ONLY>(params);
      return `${baseUri}/${params.airportName}/${params.airportCode}`;

    case PageType.COUNTRY_ONLY:
      assertPageTypeParams<PageType.COUNTRY_ONLY>(params);
      return `${baseUri}/${params.countryName}/${params.countryId}`;

    case PageType.CITY_ONLY:
      assertPageTypeParams<PageType.CITY_ONLY>(params);
      return `${baseUri}/${params.cityName}/${params.cityId}`;

    case PageType.NEIGHBORHOOD_ONLY:
      assertPageTypeParams<PageType.NEIGHBORHOOD_ONLY>(params);
      return `${baseUri}/${params.districtName}/${params.districtId}`;

    case PageType.STATION_ONLY:
      assertPageTypeParams<PageType.STATION_ONLY>(params);
      return `${baseUri}/${params.stationName}/${params.stationId}`;

    case PageType.VENDOR_IN_CITY:
      assertPageTypeParams<PageType.VENDOR_IN_CITY>(params);
      return `${baseUri}/${params.vendorId}/${params.vendorName}-car-${hireOrRental}-${params.cityName}`;

    case PageType.VENDOR_AT_AIRPORT:
      assertPageTypeParams<PageType.VENDOR_AT_AIRPORT>(params);
      return `${baseUri}/${params.vendorId}/${params.vendorName}-car-${hireOrRental}-${params.airportName}-${params.airportCode}`;

    case PageType.VENDOR_AT_STATION:
      assertPageTypeParams<PageType.VENDOR_AT_STATION>(params);
      return `${baseUri}/${params.vendorId}/${params.vendorName}-car-${hireOrRental}-${params.stationName}`;

    case PageType.VENDOR_NEIGHBOURHOOD:
      assertPageTypeParams<PageType.VENDOR_NEIGHBOURHOOD>(params);
      return `${baseUri}/${params.vendorId}/${params.vendorName}-car-${hireOrRental}-${params.districtName}-${params.cityName}`;

    case PageType.VENDOR_IN_COUNTRY:
      assertPageTypeParams<PageType.VENDOR_IN_COUNTRY>(params);
      return `${baseUri}/${params.vendorId}/${params.vendorName}-car-${hireOrRental}-${params.countryName}`;

    case PageType.SITEMAP:
      assertPageTypeParams<PageType.SITEMAP>(params);
      return `${baseUri}/${params.type}`;

    case PageType.HOME:
      return baseUri;
  }

  return "";
}

export function constructPageLink<T extends PageType>(
  pageType: T,
  params: T extends keyof PageLinkParamsMap ? PageLinkParamsMap[T] : never,
  options: PageLinkOptions,
): string {
  if ("vendorId" in params && params.vendorId.toLowerCase().includes("fake")) {
    return "#";
  }

  if (FORCE_UK_LIST.includes(pageType)) {
    options.language = "uk";
  }

  const hireOrRental =
    getLocale(options.language) === "en-GB" ? "hire" : "rental";
  let baseUri: string;
  if (pageType === PageType.HOME) {
    baseUri = options.language === "us" ? "/" : `/car/${options.language}`;
  } else {
    baseUri = `/car/${options.language}/${pageType}`;
  }
  if (!FORCE_UK_LIST.includes(pageType)) {
    baseUri = baseUri
      .replace("hire", hireOrRental)
      .replace("rental", hireOrRental);
  }

  // Special case for country only pages
  if (pageType === PageType.COUNTRY_ONLY) {
    baseUri =
      options.language === "uk"
        ? `/car/${options.language}/car-rental-in`
        : `/car/${options.language}/car-rental-in-country`;
  }

  // format params
  for (const key of Object.keys(params) as Array<keyof typeof params>) {
    if (typeof params[key] === "string") {
      params[key] = encodeURIComponent(
        formatUrlComponent(params[key] as string, pageType),
      ) as (typeof params)[typeof key];
    }
  }

  return buildPageLink(pageType, params, baseUri, hireOrRental);
}
