// Generated by @nuxtjs/svg-sprite
export const sprites = {
  "car": () => import("/usr/src/app/src/assets/sprite/gen/car.svg").then(r => r.default || r),
  "common": () => import("/usr/src/app/src/assets/sprite/gen/common.svg").then(r => r.default || r)
}
export const spriteClass = "icon";

export const spriteClassPrefix = "sprite-";

export const defaultSprite = "icons";
